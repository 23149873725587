<i18n>
[
    "header__browse--label",
    "header__menu--label",
    "shared__nav--rent",
    "shared__nav--thrift",
]
</i18n>

<template>
    <div
        :class="{
            'c-common-header--fixed': shouldFixHeader
        }"
        class="c-common-header"
    >
        <div class="c-common-header__logo-container">
            <BaseLink
                :to="appHomeRoutes.common"
                class="o-flex-vertical-center"
            >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 600 146.9" v-bind:svg-inline="''" v-bind:class="'c-common-header__logo'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M77.3 17.6c12.8 0 22.5 3.8 29.1 11.5 6.6 7.6 9.8 18.9 9.8 33.7v53.4H76.7v-45c0-14.4-5.9-21.6-17.8-21.6-13 0-19.5 7.4-19.5 22.3v44.4H0V21.8h39.1v23.6c3.3-9.5 8.1-16.5 14.3-21 6.1-4.5 14.1-6.8 23.9-6.8M214.1 21.8h39.6v94.4h-39.6V96.7c-6.5 15.3-18.9 22.9-37.3 22.9-12.6 0-22.2-3.5-28.8-10.6-6.6-7-9.9-17.1-9.9-30.3v-57h39.6V69c0 7.2 1.4 12.5 4.3 15.9 2.8 3.4 7.3 5.1 13.3 5.1 12.6 0 18.9-7.3 18.9-21.8V21.8zM333.3 119.5c-19.5 0-34-4.4-43.5-13.2-9.5-8.8-14.3-20.2-14.3-34.1V21.7h39.6v45.7c0 7.2 1.5 12.5 4.4 15.9 2.9 3.4 7.5 5.1 13.9 5.1s11-1.7 13.9-5.1c2.9-3.4 4.4-8.7 4.4-15.9V21.7h39.6v50.5c0 14-4.8 25.4-14.4 34.1-9.9 8.8-24.3 13.2-43.6 13.2M413.3 0h39.4v116.2h-39.4zM558.2 21.8l-22.8 50.8-21.3-50.8H472l41.3 85.9-21.1 39.2h44.5L600 21.8z"/></svg>
            </BaseLink>
        </div>
        <div class="c-common-header__links">
            <BaseLink
                class="c-common-header__link o-text--link"
                :to="appHomeRoutes.rent"
            >
                {{ $t('shared__nav--rent') }}
            </BaseLink>
            <BaseLink
                class="c-common-header__link o-text--link"
                :to="appHomeRoutes.thrift"
            >
                {{ $t('shared__nav--thrift') }}
            </BaseLink>
        </div>
    </div>
</template>

<script>
import { genericRouteNames } from '~coreModules/core/js/router-constants';

export default {
    name: 'CommonHeader',
    data: () => ({
        appHomeRoutes: {
            common: '/',
            rent: '/rent',
            thrift: '/thrift',
        },
    }),
    computed: {
        shouldFixHeader() {
            return this.$route.name !== genericRouteNames.home;
        },
    },
};
</script>

<style lang="scss">

.c-common-header {
    width: 100%;
    max-width: 100vw;
    height: 48px;
    display: flex;
    justify-content: space-between;
    background-color: $nu-white;
    padding: 0 $nu-spacer-2;

    &--fixed {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        z-index: map-get($zindex, topnav);
    }

    @include breakpoint(medium) {
        padding: 0 $nu-spacer-3;
    }

    @include breakpoint(large) {
        height: 64px;
    }

    &__logo-container {
        display: flex;
        justify-content: center;
        justify-self: center;
        flex: 1;
    }

    &__logo {
        height: 16px;
        fill: $nu-primary;

        @include breakpoint(large) {
            height: 24px;
        }
    }

    &__links {
        display: flex;
        align-self: center;
        position: absolute;
        right: $nu-spacer-2;

        @include breakpoint(medium) {
            right: $nu-spacer-3;
        }
    }

    &__link {
        display: inline-flex;
        margin-right: $nu-spacer-3;

        &:last-child {
            margin-right: 0;
        }
    }
}

</style>
